<template>
  <v-card :loading="isLoading">
    <v-card-title>
      Hatırlatıcı Detayı
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text v-if="originalData.id">
      <v-form v-if="originalData.reminder.note_id">
        <!-- <p class="font-weight-bold">Not Hatırlatıcısı</p> -->
        <v-row cols="12" dense>
          <v-col sm="4">
            <rs-text-field
              label="Hatırlatma Tarihi"
              :value="new Date(originalData.reminded_at).toLocaleString()"
              readonly
            />
          </v-col>
          <v-col sm="4">
            <rs-text-field
              label="Durum"
              :value="originalData.closed_at === null ? 'Açık' : 'Kapalı'"
              readonly
            />
          </v-col>
          <v-col sm="4">
            <rs-action id="closeButton" v-if="originalData.closed_at === null">
              Kapat
            </rs-action>
            <v-dialog
              v-if="originalData.closed_at === null"
              v-model="dialog"
              persistent
              max-width="600px"
              activator="#closeButton"
            >
              <!-- <template v-slot:activator="{ on, attrs }">
                <rs-action v-bind="attrs" v-on="on">Kapat</rs-action>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5">Hatırlatıcıyı Kapat</span>
                </v-card-title>
                <v-form ref="closeForm" @submit.prevent="handleCloseFormSubmit">
                  <v-card-text>
                    <v-container>
                      <p class="mb-0">
                        Bu işlemi yaparak hatırlatıcıyı kapatmış olacaksınız.
                      </p>
                      <rs-textarea
                        label="Açıklama"
                        required
                        rows="5"
                        no-resize
                        v-model="closeText"
                        :rules="[rules.required]"
                      />
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      İptal
                    </v-btn>
                    <v-btn type="submit" color="blue darken-1" text>
                      Kaydet ve Kapat
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <template v-else>
              <p>
                Bu hatırlatıcı
                <strong>{{
                  new Date(originalData.closed_at).toLocaleString()
                }}</strong>
                zamanında
                <strong>
                  {{
                    originalData.closed_by_user
                      ? originalData.closed_by_user.name
                      : "bilinmeyen"
                  }}
                </strong>
                kişisi tarafından kapatıldı.
              </p>
              <p>
                <strong>Kapatma açıklaması:</strong>
                {{ originalData.close_text }}
              </p>
            </template>
          </v-col>
        </v-row>

        <v-divider />

        <h5>Not Bilgileri</h5>

        <v-row cols="12" dense>
          <v-col sm="4">
            <rs-text-field
              label="Konu"
              :value="originalData.subject"
              readonly
            />
          </v-col>
          <v-col sm="4">
            <rs-textarea
              :label="$t('labels.description')"
              :value="originalData.body"
              rows="5"
              no-resize
              readonly
            />
          </v-col>
          <v-col sm="4">
            <rs-action
              class="mb-2"
              @click="$refs.noteForm.show(originalData.reminder.note_id)"
            >
              Notu Göster
            </rs-action>

            <p v-if="originalData.reminder.note.company_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.companies.edit',
                  params: { id: originalData.reminder.note.company_id },
                  hash: '#notes',
                }"
                >bir yönetim şirketi</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.cluster_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.clusters.edit',
                  params: { id: originalData.reminder.note.cluster_id },
                  hash: '#notes',
                }"
                >bir toplu yaşam alanı</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.house_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.houses.edit',
                  params: { id: originalData.reminder.note.house_id },
                  hash: '#notes',
                }"
                >bir bağımsız bölüm</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.resident_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.residents.edit',
                  params: { id: originalData.reminder.note.resident_id },
                  hash: '#notes',
                }"
                >bir sakin</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.bank_account_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.bank-accounts.edit',
                  params: { id: originalData.reminder.note.bank_account_id },
                  hash: '#notes',
                }"
                >bir banka hesabı</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.safe_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.safes.edit',
                  params: { id: originalData.reminder.note.safe_id },
                  hash: '#notes',
                }"
                >bir kasa</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.provider_id">
              Bu not
              <router-link
                :to="{
                  name: 'definitions.cluster-providers.edit',
                  params: { id: originalData.reminder.note.provider_id },
                  hash: '#notes',
                }"
                >bir tedarikçi</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.invoice_id">
              Bu not
              <router-link
                :to="{
                  name: 'expenses.invoices.edit',
                  params: { id: originalData.reminder.note.invoice_id },
                  hash: '#notes',
                }"
                >bir fatura</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.fixture_id">
              Bu not
              <router-link
                :to="{
                  name: 'other-definitions.fixtures.fixtures.edit',
                  params: { id: originalData.reminder.note.fixture_id },
                  hash: '#notes',
                }"
                >bir demirbaş</router-link
              >
              hakkında.
            </p>

            <p v-else-if="originalData.reminder.note.care_id">
              Bu not
              <router-link
                v-if="originalData.reminder.note.care.type === 1"
                :to="{
                  name: 'communication.breakdowns.edit',
                  params: { id: originalData.reminder.note.care_id },
                  hash: '#notes',
                }"
              >
                bir arıza
              </router-link>

              <router-link
                v-else-if="originalData.reminder.note.care.type === 2"
                :to="{
                  name: 'communication.suggestion.edit',
                  params: { id: originalData.reminder.note.care_id },
                  hash: '#notes',
                }"
              >
                bir öneri
              </router-link>

              <router-link
                v-else-if="originalData.reminder.note.care.type === 3"
                :to="{
                  name: 'communication.complaint.edit',
                  params: { id: originalData.reminder.note.care_id },
                  hash: '#notes',
                }"
              >
                bir şikayet
              </router-link>

              hakkında.
            </p>
          </v-col>
        </v-row>

        <NoteForm
          @dismissed="handleFormDismiss"
          @saved="load()"
          ref="noteForm"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";
import NoteForm from "@/view/content/Notes/NoteForm";
import { mapActions } from "vuex";

export default {
  name: "ReminderForm",
  mixins: [hasForm],
  components: {
    NoteForm,
  },
  props: {
    /**
     * Reminder Schedule ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      dialog: false,
      closeText: null,
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("reminder-schedules", this.id)
        .then((response) => {
          this.loadData(response);

          if (response.data.data.reminder.note.author_cluster_id) {
            this.updateClusterId(
              response.data.data.reminder.note.author_cluster_id
            );
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCloseFormSubmit() {
      if (this.isLoading || !this.$refs.closeForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.$api
        .post(`reminder-schedules/${this.id}/close`, {
          close_text: this.closeText,
        })
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.closeText = null;
          this.dialog = false;

          this.$nextTick(() => {
            this.$router.push({ name: "communication.reminders.list" });
          });
        })
        .catch((error) => this.handleError(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

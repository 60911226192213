<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      <template v-if="formData.note_type_id === 2">Görüşme Kaydı Ekle</template>
      <template v-else>Not Ekle</template>
      <p v-if="subtitle">
        <small>{{ subtitle }} hakkında</small>
      </p>
    </template>

    <template slot="body">
      <v-form
        @submit.prevent="handleFormSubmit"
        ref="form"
        :disabled="readonly ? true : disabled"
      >
        <v-row dense cols="12">
          <v-col cols="12">
            <rs-select
              :label="$t('labels.note_type')"
              :items="noteTypeList"
              :rules="[rules.required]"
              v-model="formData.note_type_id"
            />
          </v-col>

          <v-col cols="12">
            <rs-text-field
              :label="$t('labels.subject')"
              :rules="[rules.required, rules.maxLength(formData.subject, 100)]"
              v-model="formData.subject"
            />
          </v-col>

          <v-col cols="12">
            <rs-textarea
              :label="$t('labels.description')"
              :rules="[rules.required, rules.maxLength(formData.body, 500)]"
              v-model="formData.body"
              rows="5"
              no-resize
            />
          </v-col>

          <v-col cols="12">
            <rs-select
              v-model="formData.reminder_period_id"
              :label="$t('labels.reminder')"
              clearable
              :items="[
                {
                  id: 1,
                  name: 'Tek Sefer',
                },
                {
                  id: 2,
                  name: 'Günlük',
                },
                {
                  id: 3,
                  name: 'Haftalık',
                },
                {
                  id: 4,
                  name: 'Aylık',
                },
                {
                  id: 5,
                  name: 'Yıllık',
                },
              ]"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          cols="12"
          justify="center"
          v-if="formData.reminder_period_id === 1"
        >
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.reminder_date')"
              v-model="formData.reminder_date"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(
                        formData.reminder_date,
                        new Date().toISOString().substr(0, 10)
                      ),
                    ]
              "
              :disabled="disabled"
              :min="new Date().toISOString().substr(0, 10)"
            />
          </v-col>
          <v-col cols="6">
            <rs-timepicker
              v-model="formData.remind_at"
              :rules="[rules.required]"
              required
            />
          </v-col>
        </v-row>

        <v-row
          dense
          cols="12"
          justify="center"
          v-if="formData.reminder_period_id === 2"
        >
          <v-col cols="12">
            <rs-timepicker
              v-model="formData.remind_at"
              :rules="[rules.required]"
              required
              prefix="Her Gün"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          cols="12"
          justify="center"
          v-if="formData.reminder_period_id === 3"
        >
          <v-col sm="6">
            <rs-select
              v-model="formData.reminder_day_id"
              :rules="[rules.required]"
              required
              prefix="Her"
              suffix="Günü"
              :items="[
                {
                  id: 3,
                  name: 'Pazartesi',
                },
                {
                  id: 4,
                  name: 'Salı',
                },
                {
                  id: 5,
                  name: 'Çarşamba',
                },
                {
                  id: 6,
                  name: 'Perşembe',
                },
                {
                  id: 7,
                  name: 'Cuma',
                },
                {
                  id: 8,
                  name: 'Cumartesi',
                },
                {
                  id: 9,
                  name: 'Pazar',
                },
              ]"
            />
          </v-col>
          <v-col sm="6">
            <rs-timepicker
              v-model="formData.remind_at"
              :rules="[rules.required]"
              required
              prefix="Saat"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          cols="12"
          justify="center"
          v-if="formData.reminder_period_id === 4"
        >
          <v-col sm="4">
            <rs-select
              v-model="formData.reminder_day"
              :rules="[rules.required]"
              required
              prefix="Her Ayın"
              :items="monthlyReminderDays"
            />
          </v-col>
          <v-col sm="4">
            <rs-select
              v-model="formData.reminder_day_id"
              :rules="[rules.required]"
              required
              :items="[
                {
                  id: 1,
                  name: 'Günü',
                },
                {
                  id: 2,
                  name: 'İş Günü',
                },
                {
                  id: 3,
                  name: 'Pazartesi',
                },
                {
                  id: 4,
                  name: 'Salı',
                },
                {
                  id: 5,
                  name: 'Çarşamba',
                },
                {
                  id: 6,
                  name: 'Perşembe',
                },
                {
                  id: 7,
                  name: 'Cuma',
                },
                {
                  id: 8,
                  name: 'Cumartesi',
                },
                {
                  id: 9,
                  name: 'Pazar',
                },
              ]"
            />
          </v-col>
          <v-col sm="4">
            <rs-timepicker
              v-model="formData.remind_at"
              :rules="[rules.required]"
              required
              prefix="Saat"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          cols="12"
          justify="center"
          v-if="formData.reminder_period_id === 5"
        >
          <v-col sm="3">
            <rs-select
              v-model="formData.reminder_month"
              :rules="[rules.required]"
              required
              prefix="Her"
              suffix="Ayının"
              :items="
                monthList.map((name, index) => {
                  return {
                    id: index + 1,
                    name: name,
                  };
                })
              "
            />
          </v-col>
          <v-col sm="3">
            <rs-select
              v-model="formData.reminder_day"
              :rules="[rules.required]"
              required
              :items="monthlyReminderDays"
            />
          </v-col>
          <v-col sm="3">
            <rs-select
              v-model="formData.reminder_day_id"
              :rules="[rules.required]"
              required
              :items="[
                {
                  id: 1,
                  name: 'Günü',
                },
                {
                  id: 2,
                  name: 'İş Günü',
                },
                {
                  id: 3,
                  name: 'Pazartesisi',
                },
                {
                  id: 4,
                  name: 'Salısı',
                },
                {
                  id: 5,
                  name: 'Çarşambası',
                },
                {
                  id: 6,
                  name: 'Perşembesi',
                },
                {
                  id: 7,
                  name: 'Cuması',
                },
                {
                  id: 8,
                  name: 'Cumartesisi',
                },
                {
                  id: 9,
                  name: 'Pazarı',
                },
              ]"
            />
          </v-col>
          <v-col sm="3">
            <rs-timepicker
              v-model="formData.remind_at"
              :rules="[rules.required]"
              required
              prefix="Saat"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          @submit="handleFormSubmit"
          v-if="!disabled"
          hide-submit
          :hide-save-close="readonly"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  props: {
    extraParams: {
      default: () => {},
      required: false,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["noteTypeList", "monthList"]),
  },
  watch: {
    id: {
      handler() {
        this.originalData = {};

        this.formData = {
          note_type_id: 1,
          subject: null,
          body: null,
          company_id: null,
          reminder_period_id: null,
          remind_at: null,
          reminder_day_id: null,
          reminder_month: null,
          reminder_date: null,
          reminder_day: null,
        };

        if (this.id) {
          this.load();
        }
      },
      immediate: true,
    },
    "formData.reminder_period_id"(newValue) {
      if (newValue === 1) {
        this.formData.reminder_date = new Date().toISOString().substr(0, 10);
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 2) {
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 3) {
        this.formData.reminder_day_id = 3;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 4) {
        this.formData.reminder_day = 1;
        this.formData.reminder_day_id = 1;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 5) {
        this.formData.reminder_day = 1;
        this.formData.reminder_day_id = 1;
        this.formData.reminder_month = 1;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      }
    },
  },
  data() {
    return {
      id: null,
      isLoading: false,
      formData: {},
      monthlyReminderDays: [],
      originalData: {},
      readonly: false,
      subtitle: null,
    };
  },
  mounted() {
    for (let i = 1; i <= 31; i++) {
      this.monthlyReminderDays.push({
        id: i,
        name: i + ".",
      });
    }
  },
  methods: {
    show(id, readonly, subtitle) {
      this.$refs.sidePanel.show();
      this.readonly = false;

      if (id) {
        this.id = id;
        this.readonly = readonly;
      } else {
        this.id = null;
      }

      this.subtitle = subtitle || null;
    },
    hide() {
      this.formData = {};
      this.id = null;
      this.$refs.sidePanel.hide();
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get("notes", this.id)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          if (response.data.data.reminder) {
            this.formData.reminder_period_id =
              response.data.data.reminder.reminder_period_id;
          }

          this.$nextTick(() => {
            // we try to skip the reminder_period_id watcher
            for (const index in this.formData) {
              if (response.data.data[index] !== undefined) {
                this.formData[index] = response.data.data[index];
              }
            }

            if (this.formData.remind_at) {
              const array = this.formData.remind_at.split(":");
              this.formData.remind_at = array[0] + ":" + array[1];
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      for (const index in this.extraParams) {
        formData[index] = this.extraParams[index];
      }

      if (this.id) {
        this.$api
          .put(`notes/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);
            if (event && event.closeOnSave) {
              this.hide();
              this.isLoading = false;
              return;
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`notes`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);
            if (event && event.closeOnSave) {
              this.hide();
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setType(type) {
      this.formData.note_type_id = type;
    },
    handleCancelClick() {
      this.hide();
    },
  },
};
</script>
